.Hero{
    display: flex;
    justify-content: space-between;
}
.left-h{
    padding: 2rem;
    padding-top: 1.5rem;
    flex: 3;
    display: flex;
    gap: 2rem;
    flex-direction: column;
}
.right-h{
    flex: 1;
    position: relative;
    background-color: var(--orange);
}
.header-menu{
    cursor: pointer;
}
.header-menu>li:hover{
    cursor: pointer;
    color: var(--orange);
}
.the-best-add{
    margin-top: 4rem;
    background-color: #363d42;
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 13px;
    text-transform: uppercase;
    color: aliceblue;
    position: relative;
    align-items: center;
    display: flex;
    justify-content: flex-start;

}
.the-best-add>span{
    z-index: 2;
}

.the-best-add>div{
    position: absolute;
    background-color: var(--orange);
    width: 5.4rem;
    height: 80%;
    left: 8px;
    border-radius: 3rem;
    z-index: 1;
}
.hero-text{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color: white;
    text-overflow: inherit;

}

.hero-text>div:nth-of-type(3){
    font-size: 1rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;
}
.figures{
    display: flex;
    gap: 2rem;
}
.figures>div{
    display: flex;
    flex-direction: column;
}
.figures>div>span:nth-of-type(1){
    font-size: 2rem;
    color: white;
}
.figures>div>span:nth-of-type(2){
    text-transform: uppercase;
    color: var(--gray);
}
.hero-button{
    display: flex;
    gap: 1rem;
    font-weight: normal;
}

.hero-button>:nth-child(1){
    background-color: var(--orange);
    color: white;
    width: 8rem;
}
.hero-button>:nth-child(2){
    background-color: transparent;
    color: white;
    width: 8rem;
    border: 2px solid var(--orange);
    
}
.right-h>.btn{
    position: absolute;
    right: 3rem;
    top: 2rem;
    color: black;
}
.heart-rate{
    display: flex;
    flex-direction: column;
    background-color: var(--darkGrey);
    gap:1rem;
    width: fit-content;
    padding: 1rem;
    align-items: flex-start;
    position: absolute;
    right: 4rem;
    top: 7rem;
}
.heart-rate>img{
    width: 2rem;
}
.heart-rate>:nth-child(2){
    color:var(--gray);
}
.heart-rate>:nth-child(3){
    color:white;
    font-size: 1.5rem;
}
.hero-image{
    position: absolute;
    right: 8rem;
    top: 10rem;
    width: 23rem;
}
.hero-image-back{
    position: absolute;
    top: 4rem;
    right: 20rem;
    z-index: -1;
    width: 15rem;
}
.calories{
    display: flex;
    gap: 2rem;
    background-color: var(--caloryCard);
    border-radius: 5px;
    padding: 1rem;
    width :13rem;
    position: absolute;
    top: 32rem;
    right: 28rem;
   
}
.calories>img{
    width: 3rem;
}
.calories>div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.calories>div>:nth-child(1){
    color: var(--gray);
}
.calories>div>:nth-child(2){
    color: white;
    font-size: 1.5rem
}
.hero-blur{
    width: 22rem;height: 30rem;left: 0;
}