.plans-header{
    display: flex;
    gap: 4rem;
    font-size: 3rem;
    justify-content: center;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    font-style: italic;
    align-items: start;
}
.plans-container{
    margin-top: -4rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}
.plans-card{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}
.plan{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: var(--caloryCard);
    color: white; 
    padding: 1.5rem;
    width: 15rem;
}
.plan:nth-child(2){
    background-color: var(--orange);
    transform: scale(1.1);
}
.plan>svg{
    fill: white;
    width: 2rem;
    height: 2rem;
}
.plan>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}
.plan>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
}
.plan>:nth-child(5){
    font-size: 0.8rem;
}
.features{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.feature{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.feature>img{
    width: 1rem;
}
.plan>:nth-child(2)>svg{
    fill: white;
}
.plan-blur-1{
    width: 32rem;height: 23rem;left: 0;top: 6rem;
}
.plan-blur-2{
    width: 32rem;height: 23rem;top: 10rem;right: 0;
}